import '../css/app.css';
import 'rrweb-player/dist/style.css';
import Navigation from '../components/Navigation';
import { Link } from "react-router-dom";
import 'rrweb-player/dist/style.css';
import rrwebPlayer from 'rrweb-player';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'

function Documentation() { // setInteractionEvents

  const [rrweb, setRRWEB] = useState()
  const [events, setEvents] = useState()
  const [slides, setSlides] = useState()
  const player = useRef(null)

  const session = "66963e5a746627a80b4da678";
  const server = useSelector((state) => state.server.value)

  function setupPlayer()
  {
      if(rrweb)
      {
        rrweb.$destroy();
      }

      //const e = JSON.parse(inflate(events["events"], { to: 'string' }));
      const e = events["events"]
      const r = new rrwebPlayer(
      {
          target: player.current, 
          props: {
            maxScale: 0, 
            width: window.innerWidth - 440,
            mouseTail: true, 
            events: e,
            useVirtualDom: false,
            autoPlay: false,
            showController: false
          }
      }
      )

      r.addEventListener("resize", e => {
        console.log(e)
        const root = r.$$.root;
        const rrPlayer = root.querySelector(".rr-player");
        const wrapper = root.querySelector(".replayer-wrapper");
        const scaleX = (root.offsetWidth-40) / (wrapper.offsetWidth);
        wrapper.style.transform = `scale(${scaleX})`
        player.current.setAttribute('style', `height: ${(e.height * scaleX) + 40}px !important`);
      });

      setRRWEB(r)
  }

  async function getEvents() {
    getSlides()
    return
    if(!session)
      return

    try
    {
      const response = await fetch(server + `events?sessionID=${session}`);
      const e = await response.json();
      setEvents(e);
      // getInteractionEvents()
    }
    catch (error)
    {
      console.log(error)
    }
  }

  async function getSlides() {
    if(!session)
      return

    try
    {
      const response = await fetch(server + `slideGuide?sessionID=${session}`);
      const e = await response.json();
      console.log(e.images)
      setSlides(e.images);
    }
    catch (error)
    {
      console.log(error)
    }
  }


  useEffect(() => {
    if(rrweb)
    {
        rrweb.$destroy();
        setRRWEB(undefined);
    }
    getEvents()
  }, [session]);

  useEffect(() => {
    if(events === undefined || events.length === 0)
      return
    setupPlayer()
  }, [events])

  return (
    <div className="doc-app">
      <Navigation tab={2} />
      <div id="docBrowser">
        <div id="docHierarchyPanel">
          <div id="docListToolbar">
              <input id="search" placeholder='Search'/>
          </div>
          <div id="docList">
            <div className="docEntity">
              <button className="docEntityHead">
                <span class="material-symbols-outlined">
                  account_circle
                </span>Michael Catterall</button>
              <div className="docBlob">
                <Link className="docBlobHead">
                  <span class="material-symbols-outlined">
                  language
                  </span>microsoft.com</Link>
                <Link to={ "/documentation/sales-order/create-sales-order" } className="docBlobItem">
                  Create Sales Order
                </Link>
                <Link to={ "/documentation/sales-order/maintain-sales-order" } className="docBlobItem">
                  Maintain Sales Order
                </Link>
                <Link to={ "/documentation/sales-order/cancel-sales-order" } className="docBlobItem">
                  Cancel Sales Order
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="docContent">
          <div id="docHeader">
            <h1>Create a sales order</h1>
            <p>This process creates a sales order in Finance & Operations.</p>
            <div id="docHeaderMeta">
              <button className="sessionRef">66963770de7280f0f53539ca</button>
              <button className="allocationRef">Michael Catterall</button>
              <button className="websiteRef">microsoft.com</button>
            </div>
          </div>
          <div id="player">
            {
              slides && Object.values(slides).map((slide, key) => {
                
                const w = document.getElementById("player").offsetWidth - ((document.getElementById("player").offsetWidth * 0.2));
                const left = w * (slide.payload.data.x / slide.payload.data.window.width);
                const h = (slide.payload.data.window.height) * (w / slide.payload.data.window.width)
                const top = h * (slide.payload.data.y / slide.payload.data.window.height);
                // style={{"width": `${w}px`, "height": `${h}px`}}
                return (<div className="slide">
                  <div className="slidePointer" style={{ "left": (left-20), "top": (top-20) }}></div>
                  <div className="slidePointerContent">
                    <h1>{key+1}</h1>
                    <h4>Click on Features</h4>
                    <p>Clicking this will open the all features module.</p>
                  </div>
                  <div className="slideImg">
                    <img src={slide.url} style={{transformOrigin: `${left}px ${top}px`}} />
                  </div>
                </div>)
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documentation;
