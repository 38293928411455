import './css/app.css';
import 'rrweb-player/dist/style.css';
import Sessions from './screens/Sessions';
import Documentation from './screens/Documentation'
import ProcessFlowPanel from './screens/ProcessFlowPanel';
import 'reactflow/dist/style.css';
import './css/reactflow.css'
import Navigation from './components/Navigation';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() { // setInteractionEvents

  const router = createBrowserRouter([
    {
      path: "/processes",
      element: <div id="flow-flex" ><Navigation tab={0} /><ProcessFlowPanel /></div>,
    },
    {
      path: "/sessions/:session?",
      element: <Sessions />,
    },
    {
      path: "/documentation/:folder?/:article?",
      element: <Documentation />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default App;
