import ReactTimeAgo from 'react-time-ago'
import { Link } from "react-router-dom";

function SessionCards({active, card}) { // setInteractionEvents

  return (
    <Link to={"/sessions/" + card["_id"]} className={active ? "sessionListCard active" : "sessionListCard"}>
        <div className="cardHead">
            <div className="cardMeta">
                <h3 style={{display: card["environment"] ? "block": "none"}}>{card["environment"]}</h3>
                <h3 className="platform">{card["platform"]}</h3>
                <h3 className="userReport">User Reported</h3>
            </div>
            <div className="author">
                <h4>Michael Catterall</h4>
                <p>{card["author"]}</p>
            </div>
            <div className="cardDates">
                <p className="cardDuration">
                    <span className="material-symbols-outlined">
                    schedule
                    </span>
                    {(card["duration"]/1000).toString().toHHMMSS()}</p>
                <p>
                <span className="material-symbols-outlined">
                schedule
                </span>
                <ReactTimeAgo date={card["timestamp"]} locale="en-US"/></p>
            </div>
            <div className="timespan" style={{
            background: `linear-gradient(90deg, rgb(228, 228, 228) 0%, ${card["heatline"].map(h => "rgb(228, 228, 228) " + (parseInt(h)-5).toString() + "%, rgb(255, 98, 98) " + h + "%, rgb(228, 228, 228) " + (parseInt(h)+5).toString() + "%, ").join("")}rgb(228, 228, 228) 100%)`
            }}>
            </div>
        </div>
    </Link>
  );
}

export default SessionCards;
