import '../css/app.css';
import 'rrweb-player/dist/style.css';
import Navigation from '../components/Navigation';
import SessionBrowser from '../components/SessionBrowser';

function Sessions() { // setInteractionEvents

  return (
    <div className="session-app">
      <Navigation tab={1} />
      <SessionBrowser />
    </div>
  );
}

export default Sessions;
