import { createSlice } from '@reduxjs/toolkit'

export const serverURL = createSlice({
  name: 'server',
  initialState: {
    value: true ? "https://bark-server-ten.vercel.app/" : "http://localhost:5000/",
  },
  reducers: {},
})

export default serverURL.reducer