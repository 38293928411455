import { useState, useEffect, useRef } from 'react';
import '../css/app.css';
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import { useSelector } from 'react-redux'
import SessionCards from './SessionCard';
import InteractionCard from './InteractionCard';
import { useParams } from "react-router-dom";

function SessionBrowser() { // setInteractionEvents

    const [events, setEvents] = useState()
    const [sid, setSession] = useState()
    const [interactionEvents, setInteractionEvents] = useState([])
    const [showMetaPanel, setMetaPanel] = useState(true)
    const [logs, setLogs] = useState([])
    const [eventCards, setEventCards] = useState([])
    const [rrweb, setRRWEB] = useState()
    const [url, setURL] = useState("")
    const player = useRef(null)
    const playerContainer = useRef(null)
    

    const { session } = useParams();

    const server = useSelector((state) => state.server.value)

    function setupPlayer()
    {
        if(rrweb)
        {
            rrweb.$destroy();
        }

        //const e = JSON.parse(inflate(events["events"], { to: 'string' }));
        const r = new rrwebPlayer(
        {
            target: player.current, 
            props: {
            maxScale: 0, 
            showController: true,
            width: window.innerWidth - 790,
            height: (document.body.offsetHeight - 135) - 500,
            mouseTail: true, 
            events: events,
            useVirtualDom: false,
            autoPlay: false
            }
        }
        )

        r.addEventListener("event-cast", (payload) => {
        if(payload.type === "4")
            setURL(payload.data.href)
        })

        setRRWEB(r)
    }

    async function getEventCards() {
        try
        {
            const response = await fetch(server + 'eventList')
            const e = await response.json()

            if(e.length === 0)
                return

            setEventCards(e);

            setSession(e[0]["_id"])
            window.history.pushState({}, undefined, "/sessions/" + e[0]["_id"]);
        }
        catch(error)
        {
          console.log(error)
        }
    }

    async function getEvents() {
        if(!sid)
          return
    
        try
        {
          const response = await fetch(server + `events?sessionID=${sid}`);
          const e = await response.json();
          setEvents(e);
          getInteractionEvents();
          getLogs();
        }
        catch (error)
        {
          console.log(error)
        }
    }

    async function getLogs() {
        if(!sid)
          return
    
        try
        {
          const response = await fetch(server + `logs?sessionID=${sid}`);
          const e = await response.json();
          let l = Object.values(e["logs"]).flat();
          l = l.sort((a, b) => a.timestamp - b.timestamp)
          setLogs(l);
        }
        catch (error)
        {
          console.log(error)
        }
    }


    async function getInteractionEvents() {
        if(!sid)
          return
    
        try
        {
          const response = await fetch(server + `specialEvents?sessionID=${sid}`);
          const e = await response.json();
          setInteractionEvents(e["events"]);
        }
        catch (error)
        {
          console.log(error)
        }
      }
    
      useEffect(() => {
        getEventCards();
      }, []);
    
      useEffect(() => {
        if(rrweb)
        {
            rrweb.$destroy();
            setRRWEB(undefined);
        }
        getEvents()
      }, [sid]);

      useEffect(() => {
        setSession(session)
      }, [session]);
    
      useEffect(() => {
        if(events === undefined || events.length === 0)
          return
        setupPlayer()
      }, [events])
    


    return (
        <div id="sessionBrowser">
            <div id="sessionList">
                <div id="sessionListToolbar">
                    <input id="search" placeholder='Search'/>
                </div>
                <div id="sessionListCards">
                    {
                    eventCards && Object.values(eventCards).map(card => {
                        return ( <SessionCards key={card._id} active={card._id == sid} card={card} />)
                    })
                    }
                </div>
            </div>
            {sid && rrweb == undefined && <div id="sessionViewLoader">
                <div className="loader"></div>
            </div>}
            <div id="sessionView" style={{visibility: rrweb === undefined ? "hidden" : "visible"}}>
            <div id="sessionPlayer">
                <div id="playerURLBar">
                <div id="playerURLBarControl">
                    <button>
                    <span className="material-symbols-outlined">keyboard_arrow_down</span>
                    </button>
                    <span id="controlBreak"></span>
                    <button>
                    <span className="material-symbols-outlined">keyboard_arrow_up</span>
                    </button>
                </div>
                <div id="playerURL">
                    <p>{url}</p>
                </div>
                <button id="panelToggle" onClick={() => { setMetaPanel(!showMetaPanel) }}>
                    <span className="material-symbols-outlined">
                    {showMetaPanel ? "right_panel_close" :  "right_panel_open"}
                    </span>
                </button>
                </div>
                <div id="playerWindow" style={{gridTemplateColumns: showMetaPanel ? "auto 350px" : "auto"}}>
                    <div id="playerArea">
                    <div id="playerContent" ref={playerContainer}>
                        <div id="player" ref={player}></div>
                    </div>
                    <div id="playerData">
                        <div id="playerDataTabs">
                        <div id="playerDataTabsList">
                            <button className="active">
                            <span className="material-symbols-outlined">code_blocks</span>
                            Console
                            </button>
                            <button>
                            <span className="material-symbols-outlined">podcasts</span>
                            Network
                            </button>
                            <button>
                            <span className="material-symbols-outlined">warning</span>
                            Errors
                            </button>
                            <button>
                            <span className="material-symbols-outlined">speed</span>
                            Performance
                            </button>
                        </div>
                        <button id="closePlayerData">
                            <span className="material-symbols-outlined">
                            bottom_panel_close
                            </span>
                        </button>
                        </div>
        
                        <div id="playerDataTabContent">
                        {
                            logs && logs.map((log, key) => {return (
                            <div key={key} className={`playerDataLog ${log.data.payload.level}`}>
                                <p className="time">{((log.timestamp - events[0]["timestamp"])/1000).toString().toHHMMSS()}</p>

                                <p>{log.data.payload.payload.join("\n").toString()}</p>
                                <p className="trace">{log.data.payload?.trace.join("\n").toString()}</p>
                            </div>
                            )})
                        }
                        </div>
                    </div>
                    </div>
                    <div id="playerPanel" style={{ display: showMetaPanel ? "flex": "none" }}>
                    <div id="playerPanelHeader">
                        <p>Michael Catterall</p>
                        <span className="material-symbols-outlined">open_in_new</span>
                    </div>

                    <div id="playerPanelMeta">
                        <div className="playerPanelMetaBlock">
                        <p className="label">Author</p>
                        <p className="value">hey@michaelcatterall.co.nz</p>
                        </div>
                        <div className="playerPanelMetaBlock">
                        <p className="label">Date</p>
                        <p className="value">2:49pm 2/07/2024</p>
                        </div>
                        <div className="playerPanelMetaBlock">
                        <p className="label">Location</p>
                        <p className="value">Auckland, New Zealand</p>
                        </div>
                        <div className="playerPanelMetaBlock">
                        <p className="label">Process</p>
                        <p className="value">null</p>
                        </div>
                    </div>

                    <div id="playerPanelEvents">
                        <div id="playerPanelEventsTab">
                        <button className="active">
                            <span className="material-symbols-outlined">
                            ads_click
                            </span>
                            Events
                        </button>
                        <button>
                            <span className="material-symbols-outlined">
                            chat_bubble
                            </span>
                            Comments
                        </button>
                        <button>
                            <span className="material-symbols-outlined">
                            labs
                            </span>
                            Create Test
                        </button>
                        </div>
                        <div id="playerPanelEventList">
                        {
                            Object.values(interactionEvents).map((e, key) => {
                            return <InteractionCard
                                key={key}
                                rrweb={() => rrweb.goto(e.timestamp - events[0]['timestamp'])}
                                event={e}
                                timestamp={((e.timestamp - events[0]['timestamp'])/1000).toString().toHHMMSS()}
                            />
                            })
                        }

                        </div>
                    </div>

                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SessionBrowser;
