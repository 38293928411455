import { useState } from 'react';

import '../css/select-process-grid.css';

 
export default function CreateBlockForm() {

  const [search, setSearch] = useState("");

  return (
    <div id="select-grid-popup">
      <div id="popup-header">
        <h3>Choose a process</h3>
        <p>Select from the list of extracted processes.</p>
      </div>
      <div id="select-grid">
        <div className="process-preview">
          <div className="process-thumbnail"></div>
          <div>
             <h3>Sales Order Process</h3>
            <p>Created by Michael Catterall</p>
          </div>
        </div>
        <div className="process-preview">
          <div className="process-thumbnail"></div>
          <div>
             <h3>Procure To Pay Process</h3>
            <p>Created by Michael Catterall</p>
          </div>
        </div>
        <div className="process-preview">
          <div className="process-thumbnail"></div>
          <div>
             <h3>Order To Cash Process</h3>
            <p>Created by Michael Catterall</p>
          </div>
        </div>
        <div className="process-preview">
          <div className="process-thumbnail"></div>
          <div>
             <h3>Vendor Creation Process</h3>
            <p>Created by Michael Catterall</p>
          </div>
        </div>
        <div className="process-preview">
          <div className="process-thumbnail"></div>
          <div>
            <h3>HR Process</h3>
            <p>Created by Michael Catterall</p>
          </div>
        </div>
      </div>
    </div>
  );
}