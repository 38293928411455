import React, { useState } from 'react';
import { getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow';

import '../css/labels.css';
import VariableIcon from '../icons/variable.svg';

export default function VariablesEdgeLabel({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data})
{

  const [showVariables, toggleVariables] = useState(false);  
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
            <div
            onMouseEnter={() => toggleVariables(true)}
            onMouseLeave={() => toggleVariables(false)}
            style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                pointerEvents: 'all',
            }}
            className="nodrag nopan custom-label"
            >
            <button 
                className="variable-icon" 
                style={{display: !showVariables ? 'inline-flex': 'none'}} 
            >
                <img src={VariableIcon}/>
            </button>
            <div className="variable-grid" style={{display: showVariables ? 'inline-flex': 'none'}}>
                {
                    Object.values(data.variables).map(variable => <span className="variable">{variable}</span>)
                }
            </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};