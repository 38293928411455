import { useState } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';

import '../css/create-form.css';

 
export default function CreateBlockForm({close, data, fitView}) {

  const [name, setName] = useState("");
  const [tags, setTags] = useState("");
  const [asignees, setAssignees] = useState("");
  const [description, setDescription] = useState("");

  const tagOptions = [
    { value: 'approval', label: 'Approval' },
    { value: 'reject', label: 'Reject' },
    { value: 'broken', label: 'Broken' }
  ]
  
  const asigneesOptions = [
    { value: 'michael_catterall', label: 'Michael Catterall' },
    { value: 'james_farrierr', label: 'James Farrier' }
  ]


  const newNode = {
    id: data.id,
    position: data.screenToFlowPosition({
      x: data.event.clientX,
      y: data.event.clientY,
    }),
    type: "processblock",
    data: { 
      "title": name,
      "description": description,
      "assignees": asignees,
      "tags": tags,
      "flowCount": 0
     },
     "width": 332,
     "height": 126,
    origin: [0.5, 0.0],
  };

  function submitForm(e)
  {
    e.preventDefault();
    e.stopPropagation();

    data.setNodes((nds) => nds.concat(newNode));
    data.setEdges((eds) =>
      eds.concat({ id: data.id, type: "bezier", "style": { strokeWidth: 5, stroke: "#D2E3F1" }, animated: false, source: data.connectingNodeId.current, target: data.id })
    );
    fitView({
      nodes: [{ id: "1" }],
      duration: 500,
      minZoom: 2,
      maxZoom: 0.2
    });
    close();
  }

  const onChangeAssignee = (
    e
  ) => {
    setAssignees(Object.values(e).map(person => ({"avatar": "", "name": person.label})))
  };

  const onChangeTags = (
    e
  ) => {
    setTags(e)
  };

  return (
    <form id="popup-form" onSubmit={submitForm}>
      <div id="popup-header">
        <h3>Create new step</h3>
        <p>Create a custom step for this process.</p>
      </div>
      <div id="popup-fields">
        <div className="input-group">
          <label>Name *</label>
          <input required placeholder="" value={name} onInput={e => setName(e.target.value)}/>
        </div>
        <div className="input-group">
          <label>Tags</label>
          <Select 
            isMulti
            name="asignees"
            className="basic-multi-select"
            classNamePrefix="select"
            options={tagOptions} 
            onChange={onChangeTags}
          />
        </div>
        <div className="input-group">
          <label>Description *</label>
          <textarea required placeholder="" onInput={e => setDescription(e.target.value)}>{description}</textarea>
        </div>
        <div className="input-group">
          <label>Asignees</label>
          <Select 
            isMulti
            name="asignees"
            className="basic-multi-select"
            classNamePrefix="select"
            options={asigneesOptions} 
            onChange={onChangeAssignee}
          />
        </div>
      </div>
      <div id="popup-footer">
        <button type="button" onClick={close}>Cancel</button>
        <button className="submit">Create step</button>
      </div>
    </form>
  );
}