
import Dagre from '@dagrejs/dagre';

import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import _ from 'lodash';

import ReactFlow, {
    Panel,
    ReactFlowProvider,
    Controls,
    Background,
    addEdge,
    useNodesState,
    useEdgesState,
    useReactFlow,
    useNodesInitialized
} from 'reactflow';
 
import ProcessBlock from '../components/ProcessBlock.js';
import VariablesEdgeLabel from '../components/VariablesEdge.js';
import ProcessCommand from '../components/ProcessCommand.js';
import CreateBlockForm from '../components/CreateBlockForm';
import ProcessSelectorGrid from "../components/ProcessSelectorGrid.js";

import { initialNodes, initialEdges } from '../node-edges.js';

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, options) => {
  g.setGraph({ 'rankdir': options.direction, 'nodesep': 75, 'edgesep': 75, 'ranksep': 75 });

  nodes.forEach((node) => g.setNode(node.id, node));
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const { x, y, height, width } = g.node(node.id);
      let xPos = x;
      let yPos = y - height/2;
      return { ...node, position: { "x": xPos, "y": yPos } };
    }),
    edges,
  };
};

let id = 1;
const getId = () => `NEW-${id++}`;

const options = {
  includeHiddenNodes: false,
};

const LayoutFlow = (props) => {

  const reactFlow  = useReactFlow();
  console.log("Hello")

  const [showCreateForm, toggleCreateForm] = useState(false)
  const [newNodeData, setNewNodeData] = useState({})
  const [rfInstance, setRfInstance] = useState(null);
  const [showSelectProcess, toggleSelectProcess] = useState(false);

  const nodesInitialized = useNodesInitialized(options);
  
  const nodeTypes = useMemo(() => ({ processblock: ProcessBlock }), []);  
  const edgeTypes = useMemo(() => ({ variablesedgelabel: VariablesEdgeLabel }), []);  

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  console.log(nodes)

  const connectingNodeId = useRef();

  const { screenToFlowPosition } = useReactFlow();

  const onConnect = useCallback((params) => setEdges((eds) => { addEdge(params, eds); }), [setEdges]);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {

        const id = getId();
        toggleCreateForm(true);
        setNewNodeData(
          {
            event: event, 
            id: id, 
            screenToFlowPosition: screenToFlowPosition, 
            setNodes: setNodes, 
            setEdges: setEdges, 
            connectingNodeId: connectingNodeId
          });
      }
    },
    [screenToFlowPosition, rfInstance],
  );

  const onLayout = useCallback(
    (direction) => {
      const layouted = getLayoutedElements(nodes, edges, { direction });

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        reactFlow.fitView({duration: 500});
      });
    },
    [nodes, edges]
  );

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      setNodes([...flow.nodes]);
      setEdges([...flow.edges]);
      console.log(flow)
    }
  }, [rfInstance]);

  useEffect(() => {
    const obj1 = reactFlow.toObject().nodes
    const obj2 = initialNodes
    if(obj1.length != obj2.length)
      {
        onLayout('TB')
        console.log("center")
      }

  }, [nodesInitialized, reactFlow.toObject().nodes.length]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onConnect={onConnect}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      defaultEdgeOptions={{type: "bezier", animated: false, "style": { strokeWidth: 5, stroke: "#D2E3F1" },}}
      connectionLineType='default'
      connectionLineStyle={{ strokeWidth: 5, stroke: "#D2E3F1" }}
      onInit={val => {setRfInstance(val); reactFlow.fitView({padding: 100});}}
    >
      <Background color="#f7f4f0" variant="lines" gap="100"/>
      {
        showCreateForm && <Panel className="popup-wrap">

            <div 
              className="darken" 
              onClick={() => toggleCreateForm(!showCreateForm)}
            ></div>

            <CreateBlockForm 
              data={newNodeData} 
              close={() => toggleCreateForm(false)} 
              fitView={reactFlow.fitView} 
            />

        </Panel>
      }

{
        showSelectProcess && <Panel className="side-popup">
            <ProcessSelectorGrid close={() => toggleSelectProcess(false)} />
        </Panel>
      }

      <Panel position="top-left" id="process-hierarchy">
        <ProcessCommand 
          selectTestFlow={val => props.toggleTestFlow(val)} 
          onLayout={onLayout} 
          onSave={onSave} 
          toggleSelectProcess={() => toggleSelectProcess(!showSelectProcess)}
        />
      </Panel>


      <Controls position="bottom-right" />

    </ReactFlow>
  );
};

export default function () {
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  );
}