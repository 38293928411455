import { useCallback, useState } from 'react';
import { Background, Handle, Position } from 'reactflow';
import ApproveIcon from '../icons/approve.svg';

import '../css/flow.css';

 
export default function ProcessBlock({ data, isConnectable,  }) {
  const blockStyle = data.title.includes("Approve") ? "process-block green" : "process-block";

  return (
    <div>
    <Handle 
        type="target" 
        position={Position.Top} 
        id={`${data.title} top`} 
        key={`${data.title} top`} 
        isConnectable={isConnectable}
    />
      <div className={blockStyle}>
            {data.flowCount > 0 ? <button className="flow-count"></button> : <div></div>}
            <h3 className="block-title">{data.title}</h3>
            <div className="block-modules">
              {data.description && <p className="block-desc">{data.description}</p>}

              {
                  data?.assignees && data.assignees.length ? <div className="block-assignees" >
                      <div className="block-avatar" style={{backgroundImage: `url(${ApproveIcon})`}}></div>
                      <p>{data.assignees[0].name}</p>
                  </div> : <></>
              }
            </div>
      </div>
      <Handle 
        type="source" 
        position={Position.Bottom} 
        id={`${data.title} bottom`} 
        key={`${data.title} bottom`} 
        isConnectable={isConnectable}
        />
    </div>
  );
}