import ReactFlow, { MarkerType } from 'reactflow';

export const initialNodes = [
    {
        "id": "start",
        "type": "startblock",
        "position": {
            "x": 258.8388324873096,
            "y": 1.8007614213197982
        },
        "x": 167,
        "y": 18.5,
        "positionAbsolute": {
            "x": 258.8388324873096,
            "y": 1.8007614213197982
        },
        "data": {
            "label": "Start"
        },
        "rank": 0,
        "width": 150,
        "height": 37,
        "selected": false,
        "dragging": false
    },
    {
        "id": "1",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 112
        },
        "width": 334,
        "data": {
            "title": "Create Vendor Invoice",
            "description": "Create and submit a vendor invoice journal for AUD currency.",
            "assignees": [],
            "flowCount": 0
        },
        "x": 167,
        "y": 179.5,
        "height": 135,
        "positionAbsolute": {
            "x": 167,
            "y": 112
        },
        "rank": 2
    },
    {
        "id": "2",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 322
        },
        "width": 334,
        "data": {
            "title": "Approve Vendor Invoice",
            "description": "AP Supervisor required to approve the vendor invoice journal.",
            "assignees": [
                {
                    "avatar": "",
                    "name": "Michael Catterall"
                }
            ],
            "flowCount": 0
        },
        "x": 167,
        "y": 419,
        "height": 194,
        "positionAbsolute": {
            "x": 167,
            "y": 322
        },
        "rank": 4
    },
    {
        "id": "3",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 591
        },
        "width": 334,
        "data": {
            "title": "Create Purchase Requisition",
            "description": "Create and submit a purchase requisition (PRQ) for hardware support.",
            "assignees": [],
            "flowCount": 0
        },
        "x": 167,
        "y": 669,
        "height": 156,
        "selected": true,
        "positionAbsolute": {
            "x": 167,
            "y": 591
        },
        "dragging": false,
        "rank": 6
    },
    {
        "id": "4",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 822
        },
        "width": 334,
        "data": {
            "title": "Approve Purchase Requisition",
            "description": "Procurement manager required to approve the purchase requisition for hardware support.",
            "assignees": [
                {
                    "avatar": "",
                    "name": "Michael Catterall"
                }
            ],
            "flowCount": 0
        },
        "x": 167,
        "y": 929,
        "height": 214,
        "selected": false,
        "positionAbsolute": {
            "x": 167,
            "y": 822
        },
        "dragging": false,
        "rank": 8
    },
    {
        "id": "5",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 1111
        },
        "width": 334,
        "data": {
            "title": "Confirm Purchase Order",
            "description": "Confirm the purchase order for hardware support.",
            "assignees": [],
            "flowCount": 0
        },
        "x": 167,
        "y": 1178.5,
        "height": 135,
        "selected": false,
        "positionAbsolute": {
            "x": 167,
            "y": 1111
        },
        "dragging": false,
        "rank": 10
    },
    {
        "id": "6",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 1321
        },
        "width": 334,
        "data": {
            "title": "Submit PO Partial Receipt",
            "description": "Submit a partial receipt and invoice for the purchase order.",
            "assignees": [],
            "flowCount": 0
        },
        "x": 167,
        "y": 1388.5,
        "height": 135,
        "selected": false,
        "positionAbsolute": {
            "x": 167,
            "y": 1321
        },
        "dragging": false,
        "rank": 12
    },
    {
        "id": "7",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 1531
        },
        "width": 334,
        "data": {
            "title": "Approve and Auto Post PO Invoice",
            "description": "Approve and automatically post the partial PO invoice.",
            "assignees": [
                {
                    "avatar": "",
                    "name": "Michael Catterall"
                }
            ],
            "flowCount": 0
        },
        "x": 167,
        "y": 1628,
        "height": 194,
        "selected": false,
        "positionAbsolute": {
            "x": 167,
            "y": 1531
        },
        "dragging": false,
        "rank": 14
    },
    {
        "id": "8",
        "type": "processblock",
        "position": {
            "x": 167,
            "y": 1800
        },
        "width": 334,
        "data": {
            "title": "Deliver Remainder After PO Cancel",
            "description": "Deliver the remainder when PO is canceled.",
            "assignees": [],
            "flowCount": 0
        },
        "x": 167,
        "y": 1867.5,
        "height": 135,
        "selected": false,
        "positionAbsolute": {
            "x": 167,
            "y": 1800
        },
        "dragging": false,
        "rank": 16
    }
]
  
  
export const initialEdges = [
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "start-1",
        "source": "start",
        "target": "1"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e1-2",
        "source": "1",
        "target": "2"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e2-3",
        "source": "2",
        "target": "3"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e3-4",
        "source": "3",
        "target": "4"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e4-5",
        "source": "4",
        "target": "5"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e5-6",
        "source": "5",
        "target": "6"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e6-7",
        "source": "6",
        "target": "7"
    },
    {
        "type": "bezier",
        "animated": false,
        "style": {
            "strokeWidth": 5,
            "stroke": "#D2E3F1"
        },
        "id": "e7-8",
        "source": "7",
        "target": "8"
    }
]