function InteractionCard({event, timestamp, rrweb}) { // setInteractionEvents

    function getType(e)
    {
        if(e.data.source === 2)
        {
            if(e.data.type === 0)
                return "MouseUp"
            if(e.data.type === 1)
                return "MouseDown"
            if(e.data.type === 2)
                return "Click"
            if(e.data.type === 3)
                return "Right Click"
        }

        if(e.data.source === 3)
            return "Scroll"
            
        if(e.data.source === 5)
        {
            return "KeyPress"
        }

        return "Other"

    }

    return (
        <div className="event" onClick={rrweb}>
            <p className="eventName">{getType(event)}</p>
            <p className="timestamp">{timestamp}</p>
        </div>
    );
}

export default InteractionCard;
