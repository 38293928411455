import React, { useState } from 'react';

import '../css/processcomand.css';
import MenuIcon from '../icons/apps.svg';
import ChevronIcon from '../icons/chevron.svg';
import Circle from '../icons/circle.svg';
import Square from '../icons/square.svg';
import Triangle from '../icons/triangle.svg';
import Hexagon from '../icons/hexagon.svg';
import Pentagon from '../icons/pentagon.svg';
import AddBlockVideo from '../videos/add-block.mp4';

export default function ProcessCommand({ onLayout, onSave, toggleSelectProcess }) {
  const [showProcess, toggleWindows] = useState(false);
  const [tree, setTree] = useState({"Sales Order Process": {expanded: true, "testflows": {"Create Batch SO": "", "Create Manual SO": ""}}})

  function toggleBranch(branch)
  {
    setTree((prevState) => ({
      ...prevState,
      [branch]: {
        ...prevState.branch,
        expanded: !tree[branch]["expanded"],
      }
    }));
  }

  return (
    <div id="processCommand">

      <div id="processCommandToolbar">
        <button id="expand" onClick={() => toggleSelectProcess()} >
          <img src={MenuIcon} />
        </button>
        <h3 id="logo"></h3>

        <div className="breadcrumb">Accounts Payable <img className="chevron" src={ChevronIcon} /><b>Purchase Order Process</b></div>
      </div>

      <div id="processFlowActions">
        
        <button>
          <img src={Square} className="active" />
          <div className="control-flyout">
            <video autoPlay muted loop>
              <source src={AddBlockVideo} type="video/mp4" />
            </video>
          </div>
        </button>
        <button><img src={Triangle} /></button>
        <button><img src={Hexagon} /></button>
      </div>

      <div id="actionButtons">
        <button onClick={() => onLayout('TB')} id="organise-process">Organise</button>
        <button onClick={() => onSave()} id="save-process">Save</button>
      </div>
    </div>
  );
}

/*

      <div id="processCommandTree" style={{display: showProcess ? 'flex' : 'none'}} >
        <input id="processSearch" type="text" placeholder="Find a process..." />
        {
          Object.keys(tree).map((branch, i) => {
            return <div key={i}>
              <button className={tree[branch]['expanded'] ? "folder expanded" : "folder"} onClick={() => toggleBranch(branch)} >Sales Order Process</button>
              <div className="processList" style={{display: tree[branch]['expanded'] ? "flex" : "none"}}>
                <button className="process" onClick={() => props.selectTestFlow(true)}>Create Batch SO</button>
                <button className="process">Create Manual SO</button>
              </div>
            </div>
          })
        }
      </div>


*/